.dropsession {
  height: 58px;
  width: auto;
  background: rgba(209, 232, 237, 0.3);
  border: 1px solid #a0dfc4;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
  padding-right: 50px;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
  .headersearchtxt {
    background: transparent;
    border: 0px solid transparent;
    border-radius: 10px;
    width: 230px;
    height: 50px;
    padding-left: 10px;
    color: #013c44;
    font-size: 18px;
  }
  .headersearchtxt::placeholder {
    color: #013c44;
    padding-left: 10px;
    font-size: 18px;
  }
  input[type="text"]:focus {
    outline: none !important;
    outline-offset: 0px !important;
  }
  .popUp {
    position: absolute;
    bottom: -100px;
    left: 0px;
    min-width: 100%;
    max-height: 160px;
    overflow: auto;
    background: #f1f9f5;
    border: 1px solid rgba(4, 102, 115, 0.3);
    box-sizing: border-box;
    border-radius: 15px;
    display: flex;
    align-items: flex-start;
    z-index: 99;
    flex-direction: column;
    top: 62px;
    div {
      font-family: "VisueltRegular";
      color: #013c44;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 248.2%;
      padding-left: 17px;
      cursor: pointer;
      width: 100%;
    }
  }

  span {
    margin-left: 17px;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #013c44;
  }
  img {
    width: 17px;
    position: absolute;
    right: 20px;
  }
}

.createLabTest {
  display: block;
  padding: 0 53px;
  .dropdown {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    gap: 20px;
    justify-content: center;
    .type_test_container {
      .Dropdown-root {
        .Dropdown-control {
          width: 299px;
          height: 58px;
          background: rgba(209, 232, 237, 0.3);
          border: 1px solid #a0dfc4;
          box-sizing: border-box;
          border-radius: 20px;
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          align-items: center;
          font-family: "VisueltRegular";
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 26px;
          /* identical to box height */

          color: #013c44;
          .Dropdown-placeholder {
            text-transform: capitalize;
          }
        }
        .dropdownMenu {
          width: 298px;
          min-height: 119px;
          max-height: 200px;
          // background: rgba(209, 232, 237, 0.3);
          background-color: #fff;
          border: 1px solid #a0dfc4;
          box-sizing: border-box;
          border-radius: 20px;
          margin-top: 10px;
          // position: inherit;
          .Dropdown-option {
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 248.2%;
            text-transform: capitalize;
            border-bottom: 0.5px solid #66b5c3;
            padding-left: 20px;
            padding-top: 10px;
            color: #013c44;
            &:hover {
              background: #c4f0dd;
            }
          }
        }
      }
    }
    .test_name_container {
      .test_name {
        width: 411px;
        height: 58px;
        background: rgba(209, 232, 237, 0.3);
        border: 1px solid #a0dfc4;
        box-sizing: border-box;
        border-radius: 20px;
        .input_text {
          background-color: transparent;
          border: none;
          outline: none;
          width: 80%;
          height: 100%;
          padding-left: 20px;
          font-family: "VisueltRegular";
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 26px;
          /* identical to box height */

          color: rgba(1, 60, 68, 0.5);
        }
        .option_container {
          width: 411px;
          height: 119px;
          background: rgba(209, 232, 237, 0.3);
          border: 1px solid #a0dfc4;
          box-sizing: border-box;
          border-radius: 20px;
          margin-top: 10px;
          overflow: auto;
          .options {
            font-family: "VisueltRegular";
            width: 100%;
            word-wrap: break-word;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 248.2%;
            padding: 0 5px 0 20px;
            border-bottom: 0.5px solid #66b5c3;
            /* or 50px */

            color: #013c44;
            &:hover {
              background-color: #c4f0dd;
            }
          }
        }
      }
    }
    .descipiline_Container {
      .test_descipline {
        width: 324px;
        height: 58px;
        padding: 0px 20px;
        background: rgba(209, 232, 237, 0.3);
        border: 1px solid #a0dfc4;
        box-sizing: border-box;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        /* identical to box height */

        color: #013c44;
      }
    }
    .descipilineOption {
      width: 324px;
      height: 119px;
      background: rgba(209, 232, 237, 0.3);
      border: 1px solid #a0dfc4;
      box-sizing: border-box;
      border-radius: 20px;
      margin-top: 10px;
      overflow: auto;
      .option {
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 248.2%;
        /* or 50px */
        border-bottom: 0.5px solid #66b5c3;
        padding: 10px 5px 10px 20px;
        word-wrap: break-word;
        color: #013c44;
        cursor: pointer;
        &:hover {
          background-color: #c4f0dd;
        }
      }
    }
  }

  .add_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    margin-top: 20px;
    width: 119px;
    height: 60px;
    background: #00839B !important;
    border-radius: 15px;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 24px;
    /* identical to box height */
    color: #ffffff;
    cursor: pointer;
  }
  .add_btn_notallowed {
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    margin-top: 20px;
    width: 119px;
    height: 60px;
    background: #00839b;
    border-radius: 15px;
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    color: #ffffff;
    cursor: not-allowed;
  }
  .test_listing_container {
    width: 100%;
    height: auto;
    background: #eff1f7;
    border: 1px solid rgba(4, 102, 115, 0.3);
    box-sizing: border-box;
    border-radius: 30px;
    margin-top: 130px;
    padding-bottom: 10px;
    .listing_container {
      padding: 20px 0;
      width: 100%;
      .norecord {
        width: 400%;
        display: flex;
        justify-content: center;
        font-family: "GreycliffCFBold";
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        margin: 30px auto;
        color: #013c44;
      }
      tr {
        th {
          font-family: Greycliff CF;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;

          color: #013c44;
        }
        td {
          font-family: "VisueltRegular";
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          text-align: left;
          /* identical to box height, or 50px */
          padding-bottom: 20px;
          color: #013c44;
          input {
            border: none;
            outline: none;
            width: 100%;
            width: 276px;
            height: 58px;
            background: #ffffff;
            border: 1px solid #a0dfc4;
            box-sizing: border-box;
            border-radius: 20px;
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 26px;
            padding: 0 15px;
            color: rgba(1, 60, 68, 0.3);
          }
        }
      }
    }
  }
  .specialAdvice_Container {
    display: block;
    width: 100%;
    min-height: 303px;
    padding: 20px 30px;
    background: #eff1f7;
    border: 1px solid rgba(4, 102, 115, 0.3);
    box-sizing: border-box;
    border-radius: 30px;
    margin-top: 50px;
    .heading {
      font-family: Greycliff CF;
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 20px;
      color: #013c44;
    }
    .textarea {
      border: none;
      outline: none;
      resize: none;
      background: #ffffff;
      border: 1px solid #a0dfc4;
      box-sizing: border-box;
      border-radius: 20px;
      height: 208px;
      width: 100%;
      padding: 20px;
      font-family: "VisueltRegular";
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 26px;

      color: rgba(1, 60, 68, 0.3);
    }
  }
  .btn {
    width: 323px;
    height: 63px;
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background: #04a9c8;
    border-radius: 20px;
    margin-top: 20px;
    cursor: pointer;
  }
  .btn_notallowed {
    width: 323px;
    height: 63px;
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background: #04a9c8;
    border-radius: 20px;
    margin-top: 20px;
    cursor: not-allowed;
  }
}

// .css-1wa3eu0-placeholder {
//   font-family: "VisueltRegular" !important;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 20px;
//   line-height: 26px;
//   /* identical to box height */

//   color: rgba(1, 60, 68, 0.5) !important;
// }

// .css-lgtu0rj-indicatorContainer {
//   width: 411px;
//   height: 58px;
//   background: rgba(209, 232, 237, 0.3);
//   border: 1px solid #a0dfc4;
//   box-sizing: border-box;
//   border-radius: 20px;
//   padding-left: 20px;
// }

// .css-1pahdxg-control {
//   width: 411px !important;
//   height: 58px !important;
//   background: rgba(209, 232, 237, 0.3) !important;
//   border: 1px solid #a0dfc4 !important;
//   box-sizing: border-box;
//   border-radius: 20px !important;
//   padding-left: 20px !important;
//   outline: none !important;
//   box-shadow: none !important;
// }
.info-icon{
  padding-left: 45px;
  padding-top: 12px;
}
.info-card{
  border: 1px solid #E3EAF6;
  background-color: #EFF5FF;
  border-radius: 4px;
 margin-left: 45px;
 padding: 10px 10px;
 position: absolute;
 width: 430px;
 margin-top: 10px;

 
}
.info-heading{
  color:#005F71;
  font-size: 16px;
  font-weight: 500;
  font-family: "VisueltRegular";

}
.info-para{
  color: #005F71;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: "VisueltRegular";
  margin-top: 10px;

}