.dropsession {
  height: 58px;
  // max-width: auto;
  background: rgba(209, 232, 237, 0.3);
  border: 1px solid #a0dfc4;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
  padding-right: 50px;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;

  .headersearchtxt {
    background: transparent;
    border: 0px solid transparent;
    border-radius: 10px;
    width: 230px;
    height: 50px;
    padding-left: 10px;
    color: #013c4480;
    font-size: 18px;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }
  .headersearchtxt::placeholder {
    color: #013c4480;
    padding-left: 10px;
    font-size: 18px;
  }
  input[type="text"]:focus {
    outline: none !important;
    outline-offset: 0px !important;
  }
  .popUp {
    position: absolute;
    bottom: -100px;
    left: 0px;
    min-width: 100%;
    max-height: 160px;
    overflow: auto;
    background: #f1f9f5;
    border: 1px solid rgba(4, 102, 115, 0.3);
    box-sizing: border-box;
    border-radius: 15px;
    display: flex;
    align-items: flex-start;
    z-index: 99;
    flex-direction: column;
    top: 62px;
    div {
      font-family: "VisueltRegular";
      color: #013c44;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 248.2%;
      padding-left: 17px;
      cursor: pointer;
      width: 100%;
    }
  }

  span {
    margin-left: 17px;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #013c44;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }
  img {
    width: 17px;
    position: absolute;
    right: 20px;
  }
}
.radio_class {
  margin: 0.5rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: "";
        background: #eff1f7;
        border-radius: 100%;
        border: 1px solid #00839b;
        display: inline-block;
        width: 33.33px;
        height: 33.33px;
        position: relative;
        top: -0.2em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
        color: #00839b;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: #00839b;
          box-shadow: inset 0 0 0 4px #eff1f7;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: #00839b;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px #eff1f7;
          border-color: #046673 30%;
          background: #046673 30%;
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
