.font_two{
  font-family: "VisueltMedium";
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 248.2%;
/* identical to box height, or 50px */



color: #013C44;
}
.inner_border{
    width: 200px;
height: 58px;
background: #FFFFFF;
border: 1px solid #A0DFC4;
box-sizing: border-box;
border-radius: 20px;
font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 26px;
padding-top: 17px;
padding-left: 25px;
/* identical to box height */
color: #013C44;
}
.font_three{
    font-family: "VisueltMedium";
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 26px;
/* identical to box height */


color: #013C44;
}
.approve_btn {
  margin-top: 43px;
  border-radius: 10px;
  width: 384px;
  height: 79px;
  background: #00839B;
  cursor: pointer;
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    display: flex;
    padding-top: 22px;
    justify-content: center;
    align-items: center;
    text-align: center;
    // padding: 5px 10px;
      font-family: "GreycliffCFBold";
      color:#FFFFFF;
  }
}
.query_btn {
  margin-top: 43px;
  margin-left: 51px;
  border-radius: 10px;
  width: 384px;
  height: 79px;
  background: #FF6E4E;
  cursor: pointer;

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    display: flex;
    padding-top: 22px;
    justify-content: center;
    align-items: center;
    text-align: center;
    // padding: 5px 10px;
      font-family: "GreycliffCFBold";
      color:#FFFFFF;
  }
}
.disable_approve{
  margin-top: 43px;
  border-radius: 10px;
  width: 384px;
  height: 79px;
  // background: #00839B;
  background-color: #dfeeea;
  cursor: not-allowed;
        pointer-events: none;
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    display: flex;
    padding-top: 22px;
    justify-content: center;
    align-items: center;
    text-align: center;
    // padding: 5px 10px;
      font-family: "GreycliffCFBold";
      color: #c0c0c0;
  }
}