.createRefferalContainer{

width: 810px;
height: auto;

background: #FFFFFF;
border: 1px solid rgba(102, 181, 195, 0.5);
box-sizing: border-box;
box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
border-radius: 30px;

}

.selector{
    display: block;
    width: 699px;
    height: 58px;
}

.selectFile{
    
    width: 699px;
    // height: 58px;
    background: rgba(209, 232, 237, 0.3);
    border-radius: 20px;
// transform: rotate(-180deg);
}

#list{
    width: 699px;
    height: auto;
    background: rgba(209, 232, 237, 0.3);
    opacity: 0.6;
    border-radius: 20px;  
    
}

.hide{
    visibility: hidden;
}

.options{
    list-style: none;
    font-size: 20px;
    color: #013C44;
    width: 639px;
    padding: 15px 0;
    cursor: pointer;

}

.reffnotes{
    font-family: 'GreycliffCFBold';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin: 50px 0 20px 0;
    color: #013C44;
    }

.inputfield{
    width: 699px;
    height: 252px;
    border-radius: 20px;
    background: rgba(209, 232, 237, 0.3);
    border: none;
    outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
    -ms-appearance: none;

   

}

.inputfield::placeholder {
    color: #013C44;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    padding: 20px 30px;
  }

  .button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 323px;
        height: 63px;
        background: #04A9C8;
        border-radius: 20px;
        margin-top: 20px;
  }

  .button_txt{
    font-family: 'GreycliffCFBold';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
  }

  .selectedText{
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    
    color: #013C44;
  }