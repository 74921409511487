.headersearchtxt {
    background: #FFFFFF;
    border: 1.56614px solid #66B5C3;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    padding-left: 5px;
    // color: #013c4480;
    color: #013C44;
    font-size: 18px;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    font-family: "VisueltRegular";
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
   
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
 

  .headersearchtxt::placeholder {
    color: #013c4480;
    padding-left: 10px;
    font-size: 18px;
    font-family: "VisueltRegular";
    // -webkit-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  .headersearchtxt1 {
      background: transparent;
      border: 1px solid red;
      border-radius: 10px;
      width: 100%;
      height: 50px;
      padding-left: 10px;
      color: #013c4480;
      font-size: 18px;
      overflow: hidden;
      white-space: pre;
      text-overflow: ellipsis;
      font-family: "VisueltRegular";
    }
    .headersearchtxt2 {
      background:#fff !important;
      // background: red !important;
      border: 1.56614px solid #66B5C3;
      border-radius: 10px;
      width: 100%;
      height: 50px;
      padding-left: 10px;
      color: #013c4480;
      font-size: 18px;
      overflow: hidden;
      white-space: pre;
      text-overflow: ellipsis;
      font-family: "VisueltRegular";
    }
    .headersearchtxt::placeholder {
      color: #013c4480;
      padding-left: 10px;
      font-size: 18px;
      font-family: "VisueltRegular";
      -webkit-appearance: none;
  }
  .Dropdown-control {
    width: 299px;
    height: 57%;
    // background: rgba(209,232,237,.3);
    border: 1px solid #a0dfc4;
    box-sizing: border-box;
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 10px 20px;
    -webkit-align-items: center;
    align-items: center;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #013c44;
    background-color: #F1F8FA;;
}
.Dropdown-option {
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 248.2%;
    text-transform: capitalize;
    border-bottom: .5px solid #66b5c3;
    padding-left: 20px;
    padding-top: 10px;
    color: #013c44;
}
.star-color{
  color: red;
  font-weight: 700;
}
.makeStyles-primaryInput2-9 .MuiOutlinedInput-root {
  border-radius: 10px;
  // height: 50px !important;
  // margin-top: 3%;
  
}
.makeStyles-primaryInput2-9 .MuiOutlinedInput-input {
  color: #013c4480 !important;
  font-size: 20px;
  font-family: "VisueltRegular" !important;
  padding-left: 15px;
  border-radius: 10px;
  background-color: #fff !important;
}
.jss69 .MuiOutlinedInput-root {
  border-radius: 10px;
  height: 50px !important;
}
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1.56614px solid #66B5C3 !important; 
  border-radius: 10px !important;
  line-height: 25px;
  height: 50px !important;
  width: 100% !important;
  outline: none;
}
// .react-tel-input .selected-flag {
//   outline: none;
//   position: relative;
//   width: 38px;
//   height: 100%;
//   padding: 0 0 0 8px;
//   border-radius: 10px !important;
//   border: 1px solid #a0dfc4 !important;
// }
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f5;
  border-radius: 10px !important;
  border: 1px solid #a0dfc4 !important;
}