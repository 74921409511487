.appointmentbord {
    height: 432px;
    margin-top: 26px;
    background: #f3fafc;
    border: 1px solid #66b5c3;
    box-sizing: border-box;
    box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.3);
  
    border-radius: 30px;
    width: 100%;    
}
.evHXOa {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 500;
    color: #046673;
    font-weight: bold;
    background-color: rgb(243, 250, 252);
    min-height: 72px;
}
.snapcontainer1 {
    height: 482px;
    margin-top: 26px;
    margin-bottom: 26px;
    background: #f3fafc;
    border: 1px solid #66b5c3;
    box-sizing: border-box;
     /* box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05); */
    box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
    border-radius: 30px;
}
.snapcontainer2 {
    padding-bottom: 10px;
    min-height: 400px;
    max-height: 920px;
    /* height: 920px; */
    margin-top: 26px;
    margin-bottom: 26px;
    background: #f3fafc;
    border: 1px solid #66b5c3;
    box-sizing: border-box;
     /* box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05); */
    box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
    border-radius: 30px;
}
.table_heading {
    color: #013C44;
    font-size: 32px;
    text-align: left;
    /* font-family: Greycliff CF; */
    /* font-family: VisueltRegular; */
    font-weight: bold;
    line-height: 24px;
    padding: 10px 0px;
    display: flex;
    margin-left: 1%;
    margin-top: 4%;
}
.table_heading1 {
    color: #013C44;
    font-size: 20px;
    text-align: right;
    font-family: GreycliffCFBold;
    font-weight: bold;
    line-height: 24px;
    padding: 19px 48px;
    display: flex;
}

.table_header{
    display: flex;
    width: 100%;
    padding: 20px;
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    /* background-color: #fff; */
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
    width: 100%;
    overflow-x: auto;
}
.table_sub_heading {
    color: #04A9C8 !important;
    font-size: 16px !important;
    min-width: 20% !important;
    font-family: GreycliffCFBold !important;
    font-weight: bold !important;
    margin-right: 32px !important;
    text-transform: none !important; 
}
.table_content{
  
    color: #046673 !important;
    font-size: 16px !important;
    font-family: VisueltRegular !important;
    font-weight: normal !important;
    text-transform: lowercase  !important;
    padding: 5px !important;
}
.add_appointment_button{
    width: 150px;
    border: 1px solid #04A9C8;
    cursor: pointer;
    height: 50px;
    display: flex;
    background: #00839B;
    box-sizing: border-box;
   
    border-radius: 15px;
    justify-content: space-around;

    color: #FFFFFF;
    display: flex;
    font-size: 15px;
    align-items: center;
    font-family: GreycliffCFBold;
    font-weight: bold;
    justify-content: center;
    text-align: right !important;
    margin-left: 800px;
    margin-top: 0px;
}
.add_visit_button{
    width: 150px;
    border: 1px solid #04A9C8;
    cursor: pointer;
    height: 50px;
    display: flex;
    background: #00839B;
    box-sizing: border-box;
   
    border-radius: 15px;
    justify-content: space-around;

    color: #FFFFFF;
    display: flex;
    font-size: 15px;
    align-items: center;
    font-family: VisueltRegular;
    font-weight: bold;
    justify-content: center;
    text-align: right !important;
    float: right;

    margin-top: 0px;
}
.header-border {
    height: 0.5px !important;
    background-color: #00839b;
    margin-top: 28px;
    margin-bottom: 40px;
}

element.style {
}
.MuiDialog-container .MuiDialog-paper {
    background: transparent;
    box-sizing: border-box;
    box-shadow: -7px 6px 7px rgb(6 99 116 / 5%);
    border-radius: 20px;
    min-height: 600px ;
    /* min-width: 650px; */
    min-width: 600px;
}
.MuiPickersBasePicker-pickerView {
    display: flex;
    max-width: 325px;
    min-width: 500px;
    min-height: 305px;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: center;
}
.view_butoon{
    background-color: #00839b !important ;
    color: white;
}



.btn_submit{
text-align: left;
justify-content: center;
padding: 20px;

}
.submit_button{
    width: 150px;
    border: 1px solid #013C44;
    cursor: pointer;
    height: 40px;
    /* display: flex; */
    background: #00839B;
    box-sizing: border-box;
   
    border-radius: 5px;
    justify-content: space-around;
    border: none;
    color: #FFFFFF;
    /* display: flex; */
    font-size: 15px;
    align-items: center;
    /* font-family: Greycliff CF; */
    font-family: VisueltRegular;
    font-weight: bold;
    justify-content: center;
    text-align: center !important;
    justify-content: center;
    /* margin-left: 180px; */
}
.submit_button:disabled{
    width: 150px;
    border: 1px solid #013C44;
    cursor: pointer;
    height: 40px;
    /* display: flex; */
    background: #bad4dd;
    box-sizing: border-box;
   
    border-radius: 5px;
    justify-content: space-around;
    border: none;
    color: #FFFFFF;
    /* display: flex; */
    cursor: not-allowed;
    font-size: 15px;
    align-items: center;
    font-family: Greycliff CF;
    font-weight: bold;
    justify-content: center;
    text-align: center !important;
    justify-content: center;
    /* margin-left: 180px; */
}
.submit_button2{
    width: 150px;
    border: 1px solid #04A9C8;
    cursor: pointer;
    height: 40px;
    display: flex;
    background: #00839B;
    box-sizing: border-box;
   
    border-radius: 15px;
    justify-content: space-around;

    color: #FFFFFF;
    display: flex;
    font-size: 15px;
    align-items: center;
    font-family: GreycliffCFBold;
    font-weight: 400;
    justify-content: center;
    text-align: center !important;
    justify-content: center;
    margin-left: 400px;
}

.update_button{
    width: 150px;
    border: 1px solid #04A9C8;
    cursor: pointer;
    height: 40px;
    /* display: flex; */
    background: #00839B;
    box-sizing: border-box;
   
    border-radius: 15px;
    justify-content: space-around;

    color: #FFFFFF;
    /* display: flex; */
    font-size: 15px;
    align-items: center;
    font-family: GreycliffCFBold;
    font-weight: 400;
    justify-content: center;
    text-align: center !important;
    justify-content: center;
    /* margin-left: 180px; */
}

.text_field{
    padding: 5px !important;
    border: none;
    background-color: #f2f8f9;
    border-radius: 10px;
    width: 100%;
}
.input_lable{
    padding: 5px;
    font-size: 26px;
    font-family: GreycliffCFBold;
    color: #00839B;
    font-family: 'VisueltRegular';
}
.input_lable_message{
    padding: 5px;
    font-size: 26px;
    color: #00839B;
    font-family: 'VisueltRegular';
}

.input_lable1{
    /* padding: 5px;
    font-size: 22px;
    /* font-family: GreycliffCFBold; */
    /* color: #00839B;
    font-family: VisueltRegular;  */
    font-family: 'VisueltRegular';
font-style: normal;
color: #00839B;
font-weight: 500;
font-size: 16px;
line-height: 20px;
}


.warning_icon{
    color: #00839B;
    text-align: center;
    margin-top: 50px;
}
.svg_icons{
    transform: scale(4.8);
  }
  .warning_msg{
      text-align: center;
      margin-top: 50px;
  }
  .warning_msg_2{
      color:#00839B ;
      font-weight: 600;
      font-size: 18px;
  }






