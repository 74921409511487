html,body{
  height: 100%;
    width: 100%;
    /* min-height: 643px; */
    min-width: 360px !important;
    overflow: hidden;
    font-size: 16px;
    padding: 0;
    margin: 0;
    font-family: "Open Sans";
    color: #4a4a4a;
}

@font-face {
  font-family: "Visuelt Pro";
  src: url("./assets/fonts/VisueltPro-Regular.eot");
  src: url("./assets/fonts/VisueltPro-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/VisueltPro-Regular.woff2") format("woff2"),
    url("./assets/fonts/VisueltPro-Regular.woff") format("woff"),
    url("./assets/fonts/VisueltPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Visuelt Pro";
  src: url("./assets/fonts/VisueltPro-Bold.eot");
  src: url("./assets/fonts/VisueltPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/VisueltPro-Bold.woff2") format("woff2"),
    url("./assets/fonts/VisueltPro-Bold.woff") format("woff"),
    url("./assets/fonts/VisueltPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Visuelt Pro";
  src: url("./assets/fonts/VisueltPro-Medium.eot");
  src: url("./assets/fonts/VisueltPro-Medium.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/VisueltPro-Medium.woff2") format("woff2"),
    url("./assets/fonts/VisueltPro-Medium.woff") format("woff"),
    url("./assets/fonts/VisueltPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-SemiBold.eot");
  src: url("./assets/fonts/Inter-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Inter-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Inter-SemiBold.woff") format("woff"),
    url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "visuelt";
  src: url("./assets/fonts/visuelt-regular.eot");
  src: url("./assets/fonts/visuelt-regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/visuelt-regular.woff2") format("woff2"),
    url("./assets/fonts/visuelt-regular.woff") format("woff"),
    url("./assets/fonts/visuelt-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.header-logo {
  width: 170px;
  height: auto;
}
.header-logoC {
  width: 170px;
  height: auto;
  margin-right: 60px;
}
.contentCB {
  max-width: 200px;
  padding-left: 30px;
  padding-right: 15px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-style: solid;
  border-color: #c4f0dd;
  font-family: "GreycliffCFBold";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #00839b;
}
.contentC {
  max-width: 200px;
  padding-left: 30px;
  padding-right: 15px;
  font-family: "GreycliffCFBold";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #00839b;
}
.valueC {
  text-transform: capitalize;
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #00839b;

  opacity: 0.8;
}
.container-doctall {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f6faff;
}
.Sidemenu {
  min-width: 160px;
  height: 100vh;
  width: 160px;
  background-color: #f6faff;
  display: flex;
  justify-content: center;
 
}
.navbar {
  width: 91px;
  background-color: #013c44;
  margin-top: 157px;
  height: 792px;
  border-radius: 30px;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  display: flex;
}
.ext_navbar{
  width: 91px;
  background-color: #013c44;
  margin-top: 157px;
  height: 500px;
  border-radius: 30px;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.ext_navbar_mob{
background-color: #013c44;
height: 100%;
border-radius: none;
display: flex;
flex-direction: column;
width: 209px;
padding-left: 18px;
padding-top: 36px;
}

.logoD{
  display: none;
}
.content-holder {
  width: calc(100% - 160px);
  background: #ffffff;
  border: 0.5px solid rgba(151, 151, 151, 0.5);
  border-radius: 30px 0px 0px 30px;
}
.header-layout {
  height: 157px;
  background: #ffffff;
  border-bottom: 0.5px solid rgba(151, 151, 151, 0.5);
  border-radius: 30px 0px 0px 0px;
  margin: 0px 32px 0px 32px;
}
.header-content {
  padding: 51px 5px 0px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerleft {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.headerright {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.headerleftChap {
  display: flex;
  justify-content: space-between;
}
.headerrightChap {
  display: flex;
  justify-content: space-between;
}
.image-holder {
  position: relative;
  display: flex;
  padding-left: 7%;
  gap: 20px;
}
.HEADER_IMG_CONTAINER {
  position: relative;
  display: flex;
  justify-content: right;
  align-items: right;
  height: 40px;
  width: auto;
  margin-top: 12px;
  margin-left: 190px;
}
.header-notify {
  /* position: absolute; */
  /* margin-right: 30px; */
  cursor: "pointer";
}
.search {
  position: relative;
  width: 364px;
  height: 50px;
}

.searchchat {
  position: relative;
  width: 700px;
  height: 50px;
}
.header-search {
  position: absolute;
  top: 18px;
  right: 0px;
}

.header-search_chat_button {
  position: absolute;
  width: 60px;
  height: 42px;
  right: 0px;
}
.headersearch {
  background: #ffffff;
  border: 1px solid #a0dfc4;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(191, 197, 245, 0.15);
  border-radius: 10px;
  width: 364px;
  height: 50px;
  padding-left: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.headersearchchat {
  background: #ffffff;
  border: 1px solid #e2f3ec;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(191, 197, 245, 0.15);
  border-radius: 10px;
  width: 85%;
  height: 50px;
  padding-left: 10px;
}

.headersearch::placeholder {
  color: #046673;
  padding-left: 10px;
}
input:focus-visible {
  outline: none;
}
.profile-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}
.layoutsession {
  position: relative;
  width: 53px;
  height: 53px;
}
.popUp {
  position: absolute;
  left: -60px;
  margin-top: 20px;
  min-width: 135px;
  height: auto;
  background: #ffffff;
  border: 1px solid #a0dfc4 !important;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  z-index: 99;
  flex-direction: column;
  box-shadow: -1px 19px 32px rgb(184 199 202 / 15%);
}
.contentpopup {
  font-family: "Greycliff CF";
  color: #013c44;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 208.2%;
  padding-left: 33px;
  cursor: pointer;
  width: 100%;
}
.profile-name {
  width: 180px;
  max-height: 62px;

  font-family: "Greycliff CF";
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: right;

  color: #00839b;
}
#zmmtg-root {
  display: none;
}
.header-profile {
  margin-left: 18px;
  cursor: pointer;
  width: 53px;
  border-radius: 50%;
}
.navimage {
  width: 27px;
  height: auto;
  cursor: pointer;
}
.navimage:hover{
  width: 35px;
  height: auto;
  cursor: pointer;
}
.navimageselection {
  width: 40px;
  cursor: pointer;
  /* background-color: #78e08f; */
  color: red;
  border-radius: 10px;
}

.navLink{
  display: flex;
color: #D9EDF1;
display: flex;
align-items: center;
margin-bottom: 24px;
cursor: pointer;
}

.navLink > svg  {
  fill:#D9EDF1;
}

.navLink > svg > path {
  stroke: #D9EDF1;
}

.navLink .navimage:hover{
  width: 27px;
}

.navLinkSelected{
  cursor: pointer;
  width: 157px;
  display: flex;
  background-color: #F6FAFF;
  border-radius: 9px;
  height: 35px;
  margin-bottom: 24px;
  color: #013C44;
  display: flex;
align-items: center;
}
.navLinkSelected > svg  {
  fill:#013C44;
}

.navLinkSelected > svg > path {
  stroke:#013C44;
}

.navimage_mob {
  width: 157px;
  height: auto;
  cursor: pointer;
}
.navimage_mob:hover{
  width: 157px;
  height: auto;
  cursor: pointer;
}
.navimageselection_mob {
  width: 157px;
  cursor: pointer;
  /* background-color: #78e08f; */
  color: #013C44;
  border-radius: 10px;
}

.notification_heading{
  color:#002931;
  font-size:35px;
  padding-left: 10px;

}
.new_notification{
  font-size: 16px;
  color: #344054;
  background-color: #F2F4F7;
  padding: 5px 10px;
  border-radius: 50px;
}
.dayes{
 
  color: #5B7B83;
  font-size: 18px;
}
.notification_sub_heading{
  color: #1A97AF;
  font-size: 20px;
  cursor: pointer;
}
.notification_para{
  font-size: 18px;
  color:  #5B7B83;
  padding:0px 20px 0px 0px;
}
.css-1tygz4e-MuiDrawer-docked .MuiDrawer-paper {
  border-radius: 40px 0px 0px 40px;
  width: 600px;
  margin:1px;
}
@media (max-width: 1260px) {
  .search {
    display: none !important;
  }
}
.children {
  padding: 37px;
}

.notificationPopup {
  position: absolute;
  top: 45px;
  z-index: 1;
  width: 358px;
  max-height: 371px;
  background: #ffffff;
  border: 1px solid #a0dfc4;
  box-shadow: 0px 23px 24px 5px rgba(4, 102, 115, 0.3);
  border-radius: 10px;
  overflow: auto;
}

.NotifyDoc {
  right: 0;
}

.NotifyPopupScreen {
  right: 60px;
}
.NOTIFY_POPUP_COUNT {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "GreycliffCFBold";
  width: 22px;
  height: 20px;
  top: -10px;
  left: 16px;
  background-color: red;
  border-radius: 50%;
  color: #ffffff;
  font-size: 10px;
  font-weight: bolder;
}
.NOTIFY_POPUP_GREEN_DESIGN {
  background-color: #78e08f;
  width: 6px;
  height: auto;
  border-radius: 10px 0 0 10px;
}
/* .NOTIFY_POPUP_PRIMARY_TEXT{
  font-family: "VisueltMedium";
  letter-spacing: -0.3px;
font-size: 10px;
font-weight: bold;
font-style: normal;
color: #013C44;
margin: 10px 20px;
word-break: break-all;
} */
.NOTIFY_POPUP_PRIMARY_TEXT {
  font-family: "VisueltMedium";
  font-size: 14px;
  font-style: normal;
  text-align: justify;
  color: #013c44;
  margin: 10px 20px;
  overflow-wrap: break-word;
  hyphens: manual;
}
.NOTIFY_POPUP_DATE {
  display: flex;
  margin: 0 0 10px 20px;
  font-family: "VisueltMedium";
  letter-spacing: -0.3px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  color: #66b5c3;
  margin-right: 20px;
  float: right;
}
.notificationList:first-child {
  margin-top: 30px;
}
.notificationList {
  display: block;
  margin: 0 auto 10px auto;
  width: 309.05px;
  min-height: 48.58px;

  background: #f3f9fb;
  border: 1px solid #c4f0dd;
  box-sizing: border-box;
  border-radius: 10px;
}
.header_popup_dot {
  margin-left: 10px;
}
.header_popup_dot2 {
  position: absolute;
  bottom: 0;
  right: 1px;
}

.header_popup_profile {
  width: 31px;
  height: 31px;
  border: 2px solid #c4f0dd;
  border-radius: 50%;
  margin-left: 10px;
}
.header_popup_drName {
  font-family: "GreycliffCFBold";
  width: 170px;
  margin-left: 10px;
  letter-spacing: -0.3px;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  color: #013c44;
}
.dotdr {
  position: absolute;
  width: 9px;
  height: 9px;
  background-color: red;
  top: 18px;
  left: 23px;
  border-radius: 50%;
}

.MuiDialogContent-root {
  background-color: #ffffff !important;
}
.MuiDialogActions-root {
  background-color: #ffffff !important;
}
/* .makeStyles-dialogLayout-82 .MuiDialog-container .MuiDialog-paper {
  width: 340px;
  border: 1px solid #66B5C3;
  height: 100px;
  min-width: 0px;
  background: #F3FAFC;
  max-height: 600px;
  min-height: 432px;
  overflow-x: hidden;
  border-radius: 8px;
} */
.css-10n2iyd-MuiInputBase-root-MuiFilledInput-root {
  background-color: #FFFFFF !important; 
    border-radius: 10px !important;
}
.css-1gctnaj-MuiInputBase-input-MuiFilledInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding-top: 8px !important;
  padding-right: 0;
  padding-bottom: 8px;
  padding-left: 12px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #013C44 !important;
  text-align: left;
  text-decoration: none;
  font-weight: 400 !important;
  word-wrap: break-word;
  /* background-color: rgba(0, 0, 0, 0.75); */
  background-color: #fff !important;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}
.css-1ywc79c {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  background-color: rgba(0, 0, 0, 0.06);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  padding-right: 12px;
  border-radius: 10px !important;
}
.css-ftr4jk{
 padding: 0px !important;
}




@media only screen and (max-width: 600px) {
  body{
    min-width: 360px !important;
    width: 100% !important;
  }
  .Sidemenu{
    min-width: 61px;
    height: 100vh;
    width: 61px;
    background-color: #013C44;
    display: flex;
    justify-content: center;
  }

  .container-doctall{
    justify-content: flex-start;
  }

  .ext_navbar{
    margin-top: 36px;
    height:330px;

  }
  .logoD{
    display: block;
  }
  .content-holder{
    width:360px;
    border-radius:0px;
  }

  .header-layout{
    height: 83px;
    
  }
  .headerleft{
    justify-content: flex-start;
  }

  
 

  .header-logo{
    width: 62px;
    height:15px;
  }

  .headerright{
    justify-content: flex-start;
  }

  .HEADER_IMG_CONTAINER{

  }
}