body {
  margin: 0;
  overflow: auto !important;
  
}

html, body {
  min-height: 100%;
  min-width: 100%
}

.CD_Container {
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  background-color: #f6faff;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
@font-face {
  font-family: "GreycliffCFBold";
  src: local("GreycliffCFBold"), url("./fonts/GreycliffCF-Bold.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "GreycliffCFHeavy";
  src: local("GreycliffCFBold"), url("./fonts/GreycliffCF-Heavy.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "GreycliffCFRegular";
  src: local("GreycliffCFRegular"), url("./fonts/GreycliffCF-Regular.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "VisueltMedium";
  src: local("VisueltMedium"), url("./fonts/visuelt-medium.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "VisueltRegular";
  src: local("VisueltRegular"), url("./fonts/visuelt-regular.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "VisueltRegular";
  src: local("VisueltRegular"), url("./fonts/visuelt-regular.otf") format("opentype");
  font-weight: bold;
}
@font-face {
  font-family: "GreycliffCF-ExtraBold";
  src: local("GreycliffCF-ExtraBold"), url("./fonts/GreycliffCF-ExtraBold.otf") format("opentype");
  font-weight: bold;
}
#root {
  input[type="text"]:focus {
    outline: none !important;
    outline-offset: 0px !important;
  }
}
.MuiDialog-container {
  .MuiDialog-paperScrollPaper {
    border-radius: 30px;
  }
}
@media (max-width: 1050px) {
  #zmmtg-root {
    .more-button {
      display: none;
    }
    .send-video-container {
      width: 90px;
    }
  }
}

.ChapDown {
  position: absolute;
  width: 297px;
  background: #ffffff;
  border: 1px solid rgba(102, 181, 195, 0.5);
  box-sizing: border-box;
  box-shadow: -7px 10px 7px rgba(6, 99, 116, 0.15);
  border-radius: 30px;
  z-index: 99;
  padding-top: 67px;
  padding-left: 30px;
  padding-right: 30px;
  overflow: auto;
  .contentCDB {
    padding: 11px;
    border-bottom: 1px solid #66b5c3;
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;

    color: #013c44;
  }
  .contentCD {
    padding: 11px;
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;

    color: #013c44;
  }
  .valueCD {
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    color: #00839b;
    margin-top: 5px;
    margin-left: 5px;
    font-size: 16px;
  }
}
.ChapDrop {
  width: 297px;
  height: 67px;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: #0a8c9d;
  border-radius: 20px;
  img {
    margin-left: 28px;
  }
  .imgarrow {
    margin-right: 28px;
    margin-left: 5px;
  }
  div {
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 113.7%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 5px;
    color: #ffffff;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #00839b;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #00839b;
  border-radius: 3px;
}
.loadingcontainer {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  width: 100%;
  height: 100vh;
  background-color: #4c4a4a75;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ContainerWrapper {
  position: relative;
  margin-top: 50px;
  margin-right: 50px;
  .Closepopup {
    position: absolute;
    transform: scale(1.5);
    top: -10px;
    color: black;
    // right: -30px;
    cursor: pointer;
    margin-left: 100%;
    margin-top: 0%;
    background-color: #ffffff;
    width: 20px;
    border: 1px solid #fff;
    height: 16px;
    border-radius: 5px;
  }
}
.UploadFile{
  display: flex;
  justify-content: center;
.ContainerWrapperFull {
  position: relative;
  margin-top: 50px;
  margin-right: 50px;
  width: 700px;
  margin-bottom: 50px;
  .Closepopup {
    position: absolute;
    transform: scale(1.5);
    top: 0px;
    right: -30px;
    cursor: pointer;
    margin-left: 102%;
    margin-top: 8%;
    background-color: #ffffff;
    width: 20px;
    border: 1px solid #fff;
    height: 16px;
    border-radius: 5px;
  }
}
}

#wc-footer-left{
  width: 90px;
  display: flex;
  .join-audio-container{
    width: 90px !important;
  }
  .send-video-container{
    width: 90px !important;
  }
}
.security-option-menu {
  visibility: hidden !important;

}

.bhoXfZ{
  background-color: 'red'!important;
}

.sc-iJKOTD{
  background-color: 'red'!important;
} 

.bhoXfZ{
  background-color: 'red'!important;
  color: #046673!important;
  
  
} 
.rdt_Pagination{
  background-color: 'red'!important;
  color: #046673!important;
  font-weight: 500;
  
}

.carousel-root {
  width: 328px;
  height: auto;
  margin: 0px;

  .carousel {
    .thumbs-wrapper {
      display: none;
    }
    .slide {
      text-align: left;
    }

    .control-dots {
      display: none;
    }
  }
}


@media only screen and (max-width: 600px) {
 html, body {
    width: 100% !important;
  min-width: 360px;
  height: 100vh;
  min-height: 100vh;
  }
}