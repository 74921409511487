.doctoreCaseNotes{
    width: 800px;
    height: auto;
    overflow: hidden;
    background: #D9EDF1;
    border: 1px solid rgba(55, 129, 152, 0.3);
    box-sizing: border-box;
    border-radius: 30px;
    
}

.docheading{
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */


    color: #013C44;
}

.divider{
    width: 837px;
    height: 0px;
    background: #FFFFFF;
    border: 0.5px solid #00839B;
}

.docContainer{
    width: 728px;
   
}

.doclabel{
    // margin-bottom: '3px' !important;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #013C44;
    
}

.inputContainer{
    width: auto;
    height: 54px;   
    background: #FFFFFF;
    border-radius: 20px;
    margin-bottom: 10px;
}

.docinput{
    width: 590px;
    margin-right: 30px;
    border: none;
    outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
    -ms-appearance: none;
}

.docinput::placeholder{
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.333333px;
    color: #013C44;
}

.docinputField{
    font-family: "VisueltRegular";
    color: #046673;
    padding: 20px 10px;
    font-size: 20px;
    width: 100%;
    height: 54px;
    background: #FFFFFF;
    border-radius: 20px;
    border: none;
    outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
    -ms-appearance: none;
    margin-bottom: 20px;

}

#doctoreCaseNotes{
    input[type=text]:focus {
        outline: none !important;
        outline-offset: 0px !important;
    }
    input[type=text] {
        outline: none !important;
        outline-offset: 0px !important;
    } 
}

.docButton{
    width: 205.26px;
    height: 50px;
    background: #00839B;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    margin-bottom: 30px;
    cursor: pointer;
}


.buttontxt{
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
}

.dropdownContainer{
    width: 728px;
    height: auto;

background: #FFFFFF;
border-radius: 20px;
padding: 20px 0px 20px 60px;
margin-bottom: 20px;
}

.dropdownText{
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    // margin: 30px;
    letter-spacing: -0.333333px;
    cursor: pointer;
    color: #013C44;
    }

.dropdownText :hover{
    color: #00839B;
}
.casedropdown{
    max-height: 200px;
    background-color: #fff;
    overflow: auto;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 10px;
    .value{
        font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #013C44;
    cursor: pointer;
    padding: 3px 5px;

    }
    .nodata{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
// .searchArray{
//     height:60px;
//     overflow-x: scroll;
//     background-color: #FFFFFF;
//     text-align: center;
    
// }
// .itemArray{
//     cursor: pointer;
//     padding: 5px;
//     font-size: 18px;
// }
// .searchArray:hover{
//     // background-color: azure;
//     color: rgb(85, 151, 90);
// }
.none{
    display: none;
}