.loginActive {
  position: relative;
  margin-top: 50px;
  margin-right: 50px;
  height: 298px;
  width: 450px;
  background: #f0f5f5;
  padding: 4%;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-family: "GreycliffCFBold";
  font-size: 20px;

  .Closepopup {
    position: absolute;
    transform: scale(1.5);
    top: -30px;
    right: -30px;
    cursor: pointer;
  }
}
