.calenderContainer {
    //min-width: 558px;
    margin-top: 26px;
    height: 432px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background: #f3fafc;
    border: 1px solid #66b5c3;
    box-sizing: border-box;
    box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
    border-radius: 30px;
    .container-border {
        background: #f3fafc;
        height: 100%;
        .icon {
        }
        .header-border {
            margin-bottom: 20px;
        }
        .column-days {
            display: flex;
            justify-content: center;
            align-items: center;
            .disabled {
                line-height: 18px !important;
            }
            .number {
                line-height: 18px !important;
            }
        }
    }
}
.calenderContainerpopup {
    margin-top: 0px;
    min-width: 500px;
    height: 563.61px;
    background: #f3fafc;
    border: 1px solid #66b5c3;
    box-sizing: border-box;
    box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
    border-radius: 30px;
    .container-border {
        background: #f3fafc;
        height: 100%;
        .icon {
        }
        .header-border {
            margin-bottom: 20px;
        }
        .days-header {
            font-style: normal;
            font-weight: bold;
            font-size: 25px;
            line-height: 30px;
            text-align: center;
        }
        .column-days {
            display: flex;
            justify-content: center;
            align-items: center;
            .disabled {
                font-size: 25px;
                line-height: 25px !important;
            }
            .number {
                font-size: 25px;
                line-height: 25px !important;
            }
        }
        .body {
            .row {
                margin-top: 30px;
            }
        }
    }
}
.popupbtn {
    width: 323px;
    height: 63px;
    margin-top: 30px;
    margin-bottom: 63px;
    margin-left: 67px;
    background: #00839b;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .btntext {
        font-family: "GreycliffCFBold";
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        text-align: center;

        color: #ffffff;
    }
}
.timeslot {
    .title {
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 32px;

        color: #013c44;
    }
    .dropdown {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 18px;
        .time {
            width: 156px;
            height: 72px;
            background: #f1f9f5;
            border: 1px solid rgba(4, 102, 115, 0.3);
            box-sizing: border-box;
            border-radius: 15px;
            margin-right: 13px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 25px;
            line-height: 32px;
            text-align: center;
            color: #013c44;
            .input1 {
                width: 33%;
                background: transparent;
                border: none;
                box-sizing: border-box;
                height: 100%;
            }
            .input2 {
                width: 33%;
                background: transparent;
                border: none;
                box-sizing: border-box;
                height: 100%;
            }
        }
        .session {
            width: 144px;
            height: 72px;
            position: relative;
            background: #f1f9f5;
            border: 1px solid rgba(4, 102, 115, 0.3);
            box-sizing: border-box;
            border-radius: 15px;
            display: flex;
            position: relative;
            align-items: center;
            cursor: pointer;
            .popUp {
                position: absolute;
                bottom: -100px;
                left: -76px;
                min-width: 144px;
                position: relative;
                background: #f1f9f5;
                border: 1px solid rgba(4, 102, 115, 0.3);
                box-sizing: border-box;
                border-radius: 15px;
                display: flex;
                align-items: flex-start;
                z-index: 99;
                flex-direction: column;
                div {
                    font-family: "VisueltRegular";
                    color: #013c44;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 25px;
                    line-height: 208.2%;
                    padding-left: 39px;
                    cursor: pointer;
                    width: 100%;
                }
            }

            span {
                margin-left: 39px;
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 25px;
                line-height: 32px;
                text-align: center;
                color: #013c44;
            }
            img {
                width: 15%;
                position: absolute;
                right: 20px;
            }
        }
    }
}
.timedropdown {
    cursor: pointer;
    .title {
        font-family: "GreycliffCFBold";
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;

        color: #013c44;
        span {
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
        }
    }
    .dropdown {
        margin-top: 5px;
        margin-bottom: 18px;

        .ddown {
            width: 358px;
            min-height: 83px;
            display: flex;
            position: relative;
            align-items: center;
            background: rgba(209, 232, 237, 0.3);
            border-radius: 20px;
            padding: 10px 0px;
            span {
                margin-left: 39px;
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 25px;
                line-height: 32px;
                text-align: center;
                color: #013c44;
            }
            .text{
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 25px;
                line-height: 32px;
                color: #013c44;
                padding-right: 54px;
                text-align: left;
            }
            img {
                width: 6%;
                position: absolute;
                right: 20px;
            }
            .placeholder {
                margin-left: 39px;
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 25px;
                line-height: 32px;
                text-align: center;
                color: rgba(1, 60, 68, 0.2);
            }
            .popUp {
                position: absolute;
                bottom: -330px;
                left: 0px;

                display: flex;
                align-items: flex-start;
                z-index: 99;
                flex-direction: column;
                min-width: 358px;
                height: 488px;
                background: #eff8fa;
                border: 1px solid #64d1e4;
                box-sizing: border-box;
                border-radius: 20px;
                max-height: 320px;
                overflow: auto;
                flex-direction: column;
                div {
                    font-family: "VisueltRegular";
                    color: #013c44;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 25px;
                    line-height: 208.2%;
                    padding-left: 39px;
                    cursor: pointer;
                    width: 100%;
                }
            }
            .popUp2 {
                position: absolute;
                bottom: -200px;
                left: 0px;
                overflow: auto;
                display: flex;
                align-items: flex-start;
                z-index: 99;
                flex-direction: column;
                min-width: 358px;
                background: #eff8fa;
                border: 1px solid #64d1e4;
                box-sizing: border-box;
                border-radius: 20px;
                max-height: 190px;
                flex-direction: column;
                padding: 10px 0px;
                div {
                    font-family: "VisueltRegular";
                    color: #013c44;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 25px;
                    line-height: 53px;
                    padding-left: 25px;
                    cursor: pointer;
                    width: 100%;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}
.snapcontainer {
    height: 432px;
    margin-top: 26px;
    background: #f3fafc;
    border: 1px solid #66b5c3;
    box-sizing: border-box;
    box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
    border-radius: 30px;
}
.schedlue {
    background-color: #eff1f7;
    border-radius: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    height: 702px;
}
@media (min-width: 768px) and (max-width: 1279px) {
    .calenderContainer {
        width: 100%;
    }
    .snapcontainer {
        width: 100%;
    }
}
@media (min-width: 1280px) and (max-width: 1599px) {
    .calenderContainer {
        width: 40%;
    }
    .snapcontainer {
        width: 58%;
    }
}
@media (min-width: 1600px) {
    .calenderContainer {
        width: 40%;
    }
    .snapcontainer {
        width: 58%;
    }
}
.Sidemenu {
    height: 100vh;
}

.MuiDialog-container {
    .MuiDialog-paper {
        background: transparent;
        //border: 1px solid rgba(102, 181, 195, 0.5);
        box-sizing: border-box;
        box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
        border-radius: 30px;
    }
}
.slotTitle {
    height: 53px;
    width: 100%;
    background: #dbede5;
    border: 1px solid rgba(4, 102, 115, 0.3);
    box-sizing: border-box;
    border-radius: 25px;
    display: flex;
    align-items: center;
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    padding-left: 37px;
    color: #046673;
   margin-bottom: 20px;
}
.msgunderconstruction{
    height: 702px;
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    border-radius: 30px;
    background-color: #fffdfd7a;
}
.disabledType{
    pointer-events: none;
    opacity: 0.4;
}
.editsloticon{
    border: 1px solid #A0DFC4;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
}
.editsolt-container{
    border: 2px solid #A0DFC4;
    border-radius: 20px;
    padding: 10px 20px;
    margin-bottom: 10px;
    .timeslot {
        .title {
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 32px;
    
            color: #013c44;
        }
        .dropdown {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 5px;
            margin-bottom: 18px;
            .time {
                width: 128px;
                height: 50px;
                background: #f1f9f5;
                border: 1px solid rgba(4, 102, 115, 0.3);
                box-sizing: border-box;
                border-radius: 15px;
                margin-right: 13px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 32px;
                text-align: center;
                color: #013c44;
                .input1 {
                    width: 33%;
                    background: transparent;
                    border: none;
                    box-sizing: border-box;
                    height: 100%;
                }
                .input2 {
                    width: 33%;
                    background: transparent;
                    border: none;
                    box-sizing: border-box;
                    height: 100%;
                }
            }
            .session {
                width: 128px;
                height: 50px;
                position: relative;
                background: #f1f9f5;
                border: 1px solid rgba(4, 102, 115, 0.3);
                box-sizing: border-box;
                border-radius: 15px;
                display: flex;
                position: relative;
                align-items: center;
                cursor: pointer;
                .popUp {
                    position: absolute;
                    bottom: -80px;
                    left: 0px;
                    min-width: 128px;
                    background: #f1f9f5;
                    border: 1px solid rgba(4, 102, 115, 0.3);
                    box-sizing: border-box;
                    border-radius: 15px;
                    display: flex;
                    align-items: flex-start;
                    z-index: 99;
                    flex-direction: column;
                    div {
                        font-family: "VisueltRegular";
                        color: #013c44;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 208.2%;
                        padding-left: 39px;
                        cursor: pointer;
                        width: 100%;
                    }
                }
    
                span {
                    margin-left: 39px;
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 32px;
                    text-align: center;
                    color: #013c44;
                }
                img {
                    width: 15%;
                    position: absolute;
                    right: 20px;
                }
            }
        }
    }
    .popupbtn {
        width: 323px;
        height: 50px;
        margin-top: 30px;
        margin-bottom: 10px;
        margin-left: 67px;
        background: #00839b;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .btntext {
            font-family: "GreycliffCFBold";
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 30px;
            text-align: center;
    
            color: #ffffff;
        }
    }
    .timedropdown {
        cursor: pointer;
        .title {
            font-family: "GreycliffCFBold";
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 30px;
    
            color: #013c44;
            span {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
            }
        }
        .dropdown {
            margin-top: 5px;
            margin-bottom: 18px;
    
            .ddown {
                width: 273px;
                min-height: 50px;
                display: flex;
                position: relative;
                align-items: center;
                background: rgba(209, 232, 237, 0.3);
                border-radius: 20px;
                padding: 10px 0px;
                span {
                    margin-left: 39px;
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 32px;
                    text-align: center;
                    color: #013c44;
                }
                .text{
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 32px;
                    color: #013c44;
                    padding-right: 54px;
                    text-align: left;
                }
                img {
                    width: 6%;
                    position: absolute;
                    right: 20px;
                }
                .placeholder {
                    margin-left: 39px;
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 32px;
                    text-align: center;
                    color: rgba(1, 60, 68, 0.2);
                }
                .popUp {
                    position: absolute;
                    bottom: -330px;
                    left: 0px;
    
                    display: flex;
                    align-items: flex-start;
                    z-index: 99;
                    flex-direction: column;
                    min-width: 358px;
                    height: 488px;
                    background: #eff8fa;
                    border: 1px solid #64d1e4;
                    box-sizing: border-box;
                    border-radius: 20px;
                    max-height: 320px;
                    overflow: auto;
                    flex-direction: column;
                    div {
                        font-family: "VisueltRegular";
                        color: #013c44;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 25px;
                        line-height: 208.2%;
                        padding-left: 39px;
                        cursor: pointer;
                        width: 100%;
                    }
                }
                .popUp2 {
                    position: absolute;
                    bottom: -200px;
                    left: 0px;
                    overflow: auto;
                    display: flex;
                    align-items: flex-start;
                    z-index: 99;
                    flex-direction: column;
                    min-width: 273px;
                    background: #eff8fa;
                    border: 1px solid #64d1e4;
                    box-sizing: border-box;
                    border-radius: 20px;
                    max-height: 190px;
                    flex-direction: column;
                    padding: 10px 0px;
                    div {
                        font-family: "VisueltRegular";
                        color: #013c44;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 53px;
                        padding-left: 39px;
                        cursor: pointer;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        img {
                            width: 8%;
                            position: absolute;
                            right: 20px;
                            height: 10%;
                        }
                    }
                }
            }
        }
    }
}
.slottime {
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    text-align: right;
    position: absolute;
    left: 40px;
    top: 12px;
    color: #ffffff;
}