.dashbord_content_container1 {
    height: auto;
    margin-top: 26px;
    margin-bottom: 26px;
    background: #f3fafc;
    border: 1px solid #66b5c3;
    box-sizing: border-box;
     /* box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05); */
    box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
    border-radius: 30px;
    padding-bottom: 20px;
    
}
.dashbord_content_container2 {
    height: auto;
    margin-top: 26px;
    margin-bottom: 26px;
    background: #FFFBFA;
    border:  2px solid #FFBEB2;
    box-sizing: border-box;
     /* box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05); */
    box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
    border-radius: 30px;
    padding-bottom: 20px;
    
}
.dashbord_content_container3 {
    height: auto;
    margin-top: 26px;
    margin-bottom: 26px;
    background: #F8FFFC;
    border: 2px solid #C4F0DD;
    box-sizing: border-box;
     /* box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05); */
    box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
    border-radius: 30px;
    padding-bottom: 20px;
    
}
.bhoXfZ {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-sizing: border-box;
    padding-right: 8px;
    padding-left: 8px;
    width: 100%;
    color: rgba(0,0,0,0.54);
    font-size: 13px;
    min-height: 56px;
    background-color: transparent !important;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: rgba(0,0,0,.12);
}
.fSQziN {
    position: relative;
    width: 100%;
    border-radius: inherit;
    overflow-x: auto;
    overflow-y: auto !important;
    min-height: 300px ;
    max-height: 534px !important;
}
.dashbord_content_container121 {
    min-height: 530px;
    margin-top: 26px;
    margin-bottom: 26px;
    background: #f3fafc;
    border: 1px solid #66b5c3;
    box-sizing: border-box;
    /* box-shadow: -7px 6px 7px rgb(6 99 116 / 5%); */
    box-shadow: -7px 6px 7px rgb(6 99 116 / 5%);
    border-radius: 30px;
    padding-bottom: 0px;
    max-height: 625px;
    overflow-y: auto;
}

.Main_headding{
    color:#00839B;
    font-size: 24px;
    text-align: left;
    font-family: Greycliff CF;
    font-weight: bold;
    line-height: 24px;
    padding-left: 10px;
    /* display: flex; */
}
.dashbord_content_head{
    background-color:#fff;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom: 1px solid #66b5c3 ;
    margin-top: 16px;
    /* border-radius: 20p
    x 10px; */
}
.dashbord_content_head1{
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom:  2px solid #FFBEB2 ;
    margin-top: 16px;
    /* border-radius: 20p
    x 10px; */
}
.dashbord_content_head2{
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom:  2px solid #C4F0DD ;
    margin-top: 16px;
    /* border-radius: 20p
    x 10px; */
}
.dashbord_head_heading{
    color:#002931;
    font-size: 20px;
    text-align: left;
    /* font-family: VisueltRegular; */
    font-family: Greycliff CF;
    font-weight: bold;
    line-height: 24px;
    padding: 10px 15px;
    display: flex;
}

.See_all{
    font-family: 'VisueltRegular';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    text-decoration-line: underline;
    cursor: pointer;
    color: #FF6E4E;
}
.edite_btn {
    align-items: right;
        /* text-align: right; */
        background-color: #00839B;
        color: #fff;
        border-radius: 5px;
        padding: 10px 10px;
        margin-left: 20px;
        cursor: pointer;
        font-family: VisueltRegular;
  }
  .edite_btn1 {
    align-items: right;
        text-align: right;
        background-color: #00839B;
        color: #fff;
        border-radius: 5px;
        padding: 10px 20px;
        margin-left: 20px;
        cursor: pointer;
        font-family: VisueltRegular;
      }
 .edite_btn1:disabled {
       align-items: right;
        background-color: #00849b3d;
        color: #fff;
        border-radius: 5px;
        padding: 10px 20px;
        margin-left: 20px;
        cursor: pointer;
        cursor: not-allowed;
        font-family: VisueltRegular;
          }
  .dashbord_content_heading{
    color:#00839B;
    font-size: 20px;
    text-align: left;
    font-family: Greycliff CF;
    font-weight: bold;
    line-height: 24px;
    padding-left:10px;
    display: flex;
}
  .dashbord_content_sub_heading{
    font-family: VisueltRegular !important;
    font-size: 14px;
    font-weight: 300;
    padding-left:10px;
  }
  .acount_box{
    margin-top: 10px;
    padding-left: 20px;
  }


  .erned{
      margin-top: 30px;
      background-color: #fff;
      width: auto;
      height: 150px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      background-image: url(../../assets/images/A.png);
      border-radius: 10px;
  }
  .erned1{
    margin-top: 30px;
    background-color: #fff;
    width: auto;
    height: 150px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-image: url(../../assets/images/B.png);
    border-radius: 10px;
}
.erned2{
    margin-top: 5px;
    background-color: #fff;
    width: auto;
    height: 150px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-image: url(../../assets/images/A.png);
    border-radius: 10px;
}
  .erned_txt{
      text-align: center;
      color: #FF6E4E;
  }
  .Outstanding{
      color: #00839B;
      text-align: center;
  }

  .table_header{
    display: flex;
    width: 100%;

}
.table_content{
    color: #046673 !important;
    font-size: 16px !important;
    font-family: VisueltRegular !important;
    font-weight: normal !important;
    text-transform: capitalize !important;
    padding-left: 20px !important;
    /* padding-left: 20px !important; */
}
.dashbord_content_sub_heading2{
    color:  #00839B;
    font-size: 19px;
    font-weight:600;
    /* font-family: Greycliff CF !important; */
}
.dashbord_content_sub_heading3{
    color:  #00839B;
    font-size: 16px;
    font-family: VisueltRegular !important;
}
.dashbord_box_cantent{
    color:  #00839B;
    font-size: 16px;
    padding-top: 10px;
    text-align: center;
}





