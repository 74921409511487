/* message_box_styles */
.dashboard_message_container {
    // min-height: 100px;
    margin-top: 26px;
    margin-bottom: 26px;
    background: #f3fafc;
    border: 1px solid #66b5c3;
    box-sizing: border-box;
    // box-shadow: -1px 19px 32px rgba(8, 180, 212, 0.15);
    // box-shadow: -7px 6px 7px rgb(6 99 116 / 5%);
    border-radius: 30px;
    padding-bottom: 0px;
    // max-height: 530px;
    overflow-y: auto;
}
.dashbord_message_heading{
    margin-top: 15px;
    color:#002931;
    font-size: 20px;
    text-align: left;
    // font-family: VisueltRegular;
    font-weight: bold;
    line-height: 24px;
    font-family: Greycliff CF;
    padding: 10px 15px;
    display: flex;
}
.messageCard{
margin:10px;
padding :10px;
border: 1px solid #D9EDF1;
border-radius: 8px;
height: 13.5%;
}
.massege_sender_name{
    font-size: 14px;
    color: hsl(190, 100%, 10%);
    font-weight: bold;
}
.message_text{
font-size: 12px;
color: #5B7B83;
font-weight: 400;
width: 250px;

}
.message_number{
font-size: 12px;
color:#FAFFFF;
background-color:#37CC8C ;
padding:5px 15px;
border-radius: 15px;
}

.ContainerWrapper1 {
    position: relative;
    margin-top: 50px;
    margin-right: 50px;
    .Closepopup1 {
      position: absolute;
      transform: scale(1.5);
      top: -10px;
      color: black;
      // right: -30px;
      cursor: pointer;
      margin-left: 101%;
      margin-top: -2%;
    //   background-color: #ffffff;
      width: 20px;
    //   border: 1px solid #fff;
    //   height: 16px;
      border-radius: 5px;
    }
  }