.sendSms{
    width: 455px;
    height: 511px;
    background: #D9EDF1;
    border: 1px solid rgba(55, 129, 152, 0.3);
    box-sizing: border-box;
    border-radius: 30px;
    padding: 30px;

    .headerContainer{
        display: flex;
        justify-content: space-between;
        .heading{
            font-family: 'GreycliffCFBold';
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #013C44;
            margin-bottom: 20px;
        }

        .mobile{
            font-family: 'VisueltRegular';
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 19px;
            color: #046673;
        }
    }
    

    .smsInput{
        width: 388px;
        height: 327px;
        font-family: "VisueltRegular";
        color: #046673;
        background: #FFFFFF;
        padding: 20px 10px;
        font-size: 20px;
        border-radius: 20px;
        border: none;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        margin-bottom: 10px;
        resize: none;
    }

    .sendBtn{
        width: 181.29px;
        height: 46px;
        font-family: 'GreycliffCFBold';
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        color: #FFFFFF;
        background: #00839B;
        border: 1px solid #04A9C8;
        box-sizing: border-box;
        border-radius: 20px;
        float: right;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        cursor: pointer;
        

        &:hover{
            background: #013C44;
        }
    }
    .sendErrBtn{
        width: 181.29px;
        height: 46px;
        font-family: 'GreycliffCFBold';
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        color: #FFFFFF;
        background: #00839B;
        opacity: 0.3;
        border: 1px solid #04A9C8;
        box-sizing: border-box;
        border-radius: 20px;
        float: right;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        cursor: not-allowed;
    }

    

    .ErrorMsg{
        color: red;
        font-family: "VisueltRegular";
    }
.ContainerWrapper{
    .MsgContainer{
        width: 423px;
        height: 298px;
        background: #F0F5F5;
        box-shadow: 0px 0px 50px rgba(38, 43, 82, 0.05);
        border-radius: 35px;
        display: flex;
        justify-content: center;
        align-items: center;

        .Container{
            font-family: 'GreycliffCFBold';
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -0.3px;

            color: #013C44;
            img{
                margin-bottom: 20px;

            }
        }
    }
}
    
}