
.registration_container{
    /* background-image: url("../../assets/images/BackgroundImage.png"); */
    /* width: 100%; */
    /* height: 100%; */
    /* position:absolute; */

    /* justify-content: center; */
    /* box-shadow: -1px 19px 32px rgba(8, 180, 212, 0.15); */
    
}
.card{
    /* margin:100px; */
    background-color: #F3FAFC;
    border-radius: 20px;
    box-shadow: -1px 19px 32px rgba(8, 180, 212, 0.15);
    border: 1px solid rgba(102, 195, 189, 0.5);
}
.buttons{
    
    text-align: left;
}
.signin_button{
    padding-top: 10px;
    text-align: left;
    margin-left: 20px;
}
.register_button{
    padding-top: 10px;
    text-align: right;
    margin-right: 20px;
}
.registration_heading{
    color: #00839B;
    font-weight: 500;
    text-align: center;
}
.lable{
    color: #00839B;
    text-align: left;
}
.reg_form_container{
    padding:20px 50px;
}

.text_field{
    padding: 5px !important;
    border: 1.5px solid #00839B;
    background-color: white;
    border-radius: 5px;
    width: 99%;
}

