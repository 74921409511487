.text_one{
    font-family: "GreycliffCFBold";
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 48px;
/* identical to box height */

display: flex;
align-items: center;
margin-top: 86px;
margin-left:481px;
letter-spacing: -0.333333px;
color: #046673;
}
.text_area_container{
    -moz-appearance: "textfield-multiline";
    -webkit-appearance: "textarea";
    overflow: auto;
position: absolute;
width: 931px;
height: 463px;
left: 164px;
top: 227px;
background: rgba(209, 232, 237, 0.3);
border: 1px solid rgba(4, 102, 115, 0.3);
box-sizing: border-box;
border-radius: 30px;
font-family:"VisueltMedium";
font-style: normal;
font-weight: normal;
font-size: 25px;
line-height: 32px;
color: #046673;
padding:20px;
cursor: pointer;
}

.textarea-replace {
    position: absolute;
    width: 450px;
    left: 50%;
    top: 50px;
    transform: translate(-50%, 0);
    padding: 18px 18px;
    min-height: 80px;
    font-size: 14px;
    color: #414141;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    &:before {
        content: attr(placeholder);
        display: block; /* For Firefox */
    }
    &:focus {
        outline: none;
        &:before {
            display: none;
            content: '';
        }
    }
}
[contenteditable][placeholder]:empty:before {
    content: attr(placeholder);
    font-family:"VisueltMedium";
font-style: normal;
font-weight: normal;
font-size: 25px;
line-height: 32px;
/* identical to box height */
margin-top: 48px;
margin-left: 102px;
display: flex;
align-items: center;
color: #046673;
  }

  .CCNS_Submit_follow_up {
    position: absolute;
    left: 170px;
    top: 719px;
    display: flex;
  
    span {
        display: flex;
        width: 234px;
        height: 79px;
        background: #00839b;
        border-radius: 10px;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-family: "GreycliffCFBold";
    }
}
.heading_query{
    position: absolute;
width: 257px;
height: 48px;
left: 170px;
top: 879px;

font-family:"GreycliffCFBold";
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 48px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: -0.333333px;

color: #046673;
}
.chap_pop_container_one{
    position: absolute;
width: 931px;
height: 316px;
left: 170px;
top: 977px;

background: #D8EDE3;
border: 1px solid #A0DFC4;
box-sizing: border-box;
border-radius: 30px;
}
.text_head{
    font-family: "VisueltMedium";
font-style: normal;
font-weight: 500;
font-size: 25px;
line-height: 32px;
/* identical to box height */

display: flex;


color: #013C44;
}
.letter_round{
    width: 85px;
height: 85px;
background: #FFFFFF;
border: 1px solid #00BE6D;
border-radius: 50px;
padding-top: 20px;
padding-right: 15px;
padding-left: 30px;
display: flex;


}
.letter_round_text{
    font-family:"GreycliffCFBold";
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 36px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;
justify-content: center;
letter-spacing: -0.333333px;
margin-left: auto;
margin-right: auto;



color: #046673;

}
.time_date_text{
    font-family: "VisueltMedium";
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 19px;
display: flex;
align-items: center;
color: #00839B;

}
.subContainer{
    position: absolute;
width: 834px;
height: 136px;
left: 66px;
top: 130px;

background: #FAFAFA;
border: 1px solid #A0DFC4;
box-sizing: border-box;
border-radius: 30px;
}
.sub_letter_round{
    position: absolute;
    width: 51px;
    height: 51px;
    left: 26px;
    top: 22px;
border-radius: 50px;
    
    background: #FFFFFF;
    border: 1px solid #00BE6D;
    box-sizing: border-box;
}
.sub_letter_round_text{
    font-family: "GreycliffCFBold";
font-style: normal;
font-weight: bold;
font-size: 26px;
line-height: 31px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.333333px;
padding-left:10px;
padding-top:5px;
color: #046673;

}
.text_head_sub{
    position: absolute;
    top: 32px;
    left:110px;
    font-family: "VisueltMedium";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 32px;
    /* identical to box height */
    
  
    
    
    color: #013C44;
}
.container_two{
    position: absolute;
width: 931px;
height: 142px;
left: 162px;
top: 1318px;

background: #D8EDE3;
border: 1px solid #A0DFC4;
box-sizing: border-box;
border-radius: 30px;
}
.close-btn{
    position: absolute;
width: 30px;
height: 30px;
left: 1141.73px;
top: 36px;
cursor: pointer;
// background: #046673;
}
.display_container{
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    scroll-snap-type: y proximity;
}
.display_container > div > div:last-child {
    scroll-snap-align: end;
  }